






import {Vue, Component, Prop} from 'vue-property-decorator';
import VuePdfApp from 'vue-pdf-app';
import 'vue-pdf-app/dist/icons/main.css';

@Component({
    components: {VuePdfApp},
})
export default class extends Vue {
    @Prop()
    public pdfUrl!: string;

    public get finalUrl() {
        return `https://mypyrop.europamiante.fr/documents/${this.pdfUrl}`;
    }

    public config: any = {
        sidebar: false,
        secondaryToolbar: {
            secondaryPresentationMode: false,
            secondaryOpenFile: false,
            secondaryPrint: true,
            secondaryDownload: true,
            secondaryViewBookmark: false,
            firstPage: true,
            lastPage: true,
            pageRotateCw: true,
            pageRotateCcw: true,
            cursorSelectTool: false,
            cursorHandTool: false,
            scrollVertical: true,
            scrollHorizontal: true,
            scrollWrapped: true,
            spreadNone: false,
            spreadOdd: false,
            spreadEven: false,
            documentProperties: false,
        },
        toolbar: {
            toolbarViewerLeft: {
                findbar: false,
                previous: true,
                next: true,
                pageNumber: true,
            },
            toolbarViewerRight: {
                presentationMode: false,
                openFile: false,
                print: true,
                download: true,
                viewBookmark: false,
            },
            toolbarViewerMiddle: {
                zoomOut: true,
                zoomIn: true,
                scaleSelectContainer: true,
            },
        },
    };
}
